import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from "react-helmet";
import { FormattedMessage } from 'react-intl';
import Layout from '../components/Layout';
import Home from '../scenes/Home';
import Presentation from '../scenes/LandingPages/Presentation';
import LocalizedLink from '../components/LocalizedLink';
import TriDymeIcon from '../media/TriDymeIconV1.svg';
import HeadMeta from '../components/HeadMeta';

const {
  REACT_COMPANY_NAME,
} = process.env;

const metaData = {
  en: {
    title: `TriDyme | Online Civil Engineering Calculation Softwares`,
    description: "Cloud based softwares for Structural, Geotechnic and Hydraulic design at International Standards (Eurocodes, ACI, AISC,...)"
  },
  fr: {
    title: `TriDyme | Logiciel de Calcul pour Génie Civil en ligne`,
    description: "Logiciels Cloud pour des calculs de Structure, de Géotechnique et d'Hydraulique aux standards internationaux (Eurocodes, ACI, AISC,...)"
  }
};


const LandingPage = ({ pageContext: { locale } }) => (
  <Layout locale={locale}>
    <HeadMeta
      locale={locale}
      metaData={metaData}
    />
    <Presentation />
  </Layout>
)

LandingPage.propTypes = {
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired
  }).isRequired
}

export default LandingPage;
